import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { color, font, elevation } from '../styles'
import iconBack from '../components/icon-back.svg'
import iconForward from '../components/icon-forward.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  @media (min-width: 1280px) {
    flex-direction: row;
  }
  @media (max-width: 1279px) {
    flex-direction: column;
  }
`
const Tile = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  padding: 1rem 1.25rem;
  box-shadow: ${elevation.button};
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    box-shadow: ${elevation.buttonHover};
  }
  img {
    width: 1.25rem;
    height: 1.25rem;
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }
  }
  div {
    flex-grow: 1;
  }
  p {
    ${font.meta}
    line-height: 0.75rem;
    color: ${color.brandDark};
    margin: 0 0 0.25rem 0;
  }
  h3 {
    line-height: 1.25rem;
    color: ${color.primary};
    margin: 0;
  }
  @media (min-width: 1280px) {
    &:first-child {
      margin-right: 1.5rem;
    }
  }
  @media (max-width: 1279px) {
    &:first-child {
      margin-bottom: 1.5rem;
    }
  }
`

const ArticleFooterNav = ({ previous, next }) => {
  let navCardPrevious = null
  let navCardNext = null

  if (previous !== null) {
    navCardPrevious = (
      <Tile to={`/${previous.slug}`}>
        <img src={iconBack} alt="back" />
        <div>
          <p>{previous.frontmatter.module}</p>
          <h3>{previous.frontmatter.title}</h3>
        </div>
      </Tile>
    )
  }
  
  if (next !== null) {
    navCardNext = (
      <Tile to={`/${next.slug}`}>
        <div>
          <p>{next.frontmatter.module}</p>
          <h3>{next.frontmatter.title}</h3>
        </div>
        <img src={iconForward} alt="back" />
      </Tile>
    )
  }

  return (
    <Wrapper>
      {navCardPrevious}
      {navCardNext}
    </Wrapper>
  )
}

export default ArticleFooterNav