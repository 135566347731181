import React from 'react'
import { Link } from 'gatsby'

const ArticleLink = ({ children, href }) => {
  if (href.startsWith('/')) {
    return <Link to={href}>{children}</Link>
  }

  const onPage = href.startsWith('#')
  
  return (
    <a
      href={href}
      target={onPage ? null : '_blank'}
      rel={onPage ? null : 'noopener noreferrer'}
    >
      {children}
    </a>
  )
}

export default ArticleLink