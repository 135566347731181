import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { color, font } from '../styles'
import { Helmet } from 'react-helmet'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import profileMac from '../images/profile-mac.png'
import ArticleLink from '../components/articleLink'
import ArticleFooterNav from '../components/articleFooterNav'
import Example from '../components/example'
import iconChevron from '../components/icon-chevron.svg'

const ArticleContents = styled.article`
  max-width: 42.5rem;
  margin: 0 auto;
`
const Breadcrumbs = styled.div`
  ${font.meta}
  color: ${color.secondary};
  margin-bottom: 1.25rem;
  img {
    margin: 0 0.25rem;
  }
  @media (min-width: 1280px) {
    display: none;
  }
  @media (max-width: 1279px) {
    display: flex;
    flex-direction: row;
  }
`
const Title = styled.h1`
  color: ${color.primary};
  @media (min-width: 1280px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 1279px) {
    margin-bottom: 0.75rem;
  }
`
const CoverImage = styled(GatsbyImage)`
  @media (min-width: 1280px) {
    margin: 0 0 2.5rem 0;
    border-radius: 0.25rem;
  }
  @media (max-width: 1279px) {
    margin: -1.5rem -1rem 1.5rem -1rem;
  }
`
const Description = styled.p`
  color: ${color.primary};
  @media (min-width: 1280px) {
    margin-bottom: 2.5rem;
  }
  @media (max-width: 1279px) {
    margin-bottom: 2rem;
  }
`
const Author = styled.div`
  display: flex;
`
const ProfilePhoto = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
`
const AuthorLabel = styled.div`
  display: flex;
  flex-direction: column;
`
const Name = styled.h4`
  color: ${color.primary}
`
const Meta = styled.p`
  ${font.meta}
  color: ${color.secondary};
  margin-bottom: 0;
`
const Divider = styled.hr`
  margin: 2.5rem 0;
`

const Article = ({ data, pageContext }) => {
  return (
    <ArticleContents>
      <Helmet>
        <title>{`${data.mdx.frontmatter.title} | Design Foundations`}</title>
        <meta name="description" content={data.mdx.frontmatter.description} />
      </Helmet>
      <CoverImage alt='Cover Photo' image={getImage(data.mdx.frontmatter.cover?.childImageSharp?.gatsbyImageData)} />
      <Breadcrumbs>
        Home
        <img src={iconChevron} alt='chevon' />
        {data.mdx.frontmatter.module}
        <img src={iconChevron} alt='chevon' />
      </Breadcrumbs>
      <Title>{data.mdx.frontmatter.title}</Title>
      <Description>{data.mdx.frontmatter.description}</Description>
      <Author>
        <ProfilePhoto src={profileMac} alt='Mac'/>
        <AuthorLabel>
          <Name>Michael Croft</Name>
          <Meta>{data.mdx.frontmatter.date} · {Math.ceil(data.mdx.wordCount.words / 200)} min read</Meta>
        </AuthorLabel>
      </Author>
      <Divider />
      <MDXProvider
        components={{
          a: ArticleLink,
          Example
        }}
      >
        <MDXRenderer
          localImages={data.mdx.frontmatter.embeddedImagesLocal}
        >
          {data.mdx.body}
        </MDXRenderer>
      </MDXProvider>
      <ArticleFooterNav previous={pageContext.previous} next={pageContext.next} />
    </ArticleContents>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        module
        title
        description
        date(formatString: "MMMM D, YYYY")
        cover {
          childImageSharp {
            gatsbyImageData (
              layout: FULL_WIDTH
            )
          }
        }
        published
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData (
              layout: FULL_WIDTH
            )
          }
        }
      }
      wordCount {
        words
      }
      body
    }
  }
`

export default Article