import * as React from 'react'
import styled from 'styled-components'
import { color, elevation } from '../styles'

const Container = styled.div`
  background: ${color.washOffWhite};
  padding: 1rem 1rem 0 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: ${elevation.card};
  margin: 2.5rem 0 2.5rem 0;
`

const Label = styled.h4`
  background: ${color.divider};
  padding: 0.125rem 0.5rem;
  border-radius: 0.5rem;
  display: inline;
  width: 4.125rem;
`

const Example = ({ children }) => {
  return (
    <Container>
      <Label>Example</Label>
      {children}
    </Container>
  )
}

export default Example